import React, { useMemo, useState } from "react";
import Markdown from "react-markdown";
import remarkGemoji from "remark-gemoji";
import remarkGfm from "remark-gfm";
import rehypeHighlight from "rehype-highlight";
import "highlight.js/styles/atom-one-dark.css";
import { SocialMediaComponent } from "@utils/social-media-component";

interface MarkdownViewerProps {
  source: string;
}

const MarkdownViewer: React.FC<MarkdownViewerProps> = (
  markDownprops: MarkdownViewerProps,
) => {
  const [markdownContent, setMarkdownContent] = useState<string>("");
  useMemo(() => {
    const loadMarkdown = async () => {
      const file = await import(`../posts/${markDownprops.source}.md`);
      const response = await fetch(file.default);
      const text = await response.text();
      setMarkdownContent(text);
    };
    loadMarkdown();
  }, [markDownprops.source]);

  return (
    <div className="prose markdown min-w-full">
      <Markdown
        children={markdownContent}
        remarkPlugins={[remarkGfm, remarkGemoji]}
        rehypePlugins={[rehypeHighlight]}
        components={{
          a: SocialMediaComponent,
        }}
      />
    </div>
  );
};

export default MarkdownViewer;
