import { SocialMediaURLHelper } from "@utils/social-media/social-media-info";
import { SocialMediaFactory } from "@utils/social-media/social-media.factory";

export const SocialMediaComponent = (props: JSX.IntrinsicElements["a"]) => {
  // props.href should be a string
  const socialMedia: SocialMediaURLHelper | undefined =
    SocialMediaFactory.create(props.href!);
  return socialMedia ? (
    socialMedia.getViewer()
  ) : (
    <a href={props.href}>{props.children}</a> // All other links
  );
};
