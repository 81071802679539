import { useState } from "react";
import MarkdownViewer from "@utils/markdown";

interface PanelProps {
  title: string;
  source: string;
  summary?: string;
  metadata: {
    date: string;
    category: string;
    avgReadingTime: string;
  };
}

export const Panel: React.FC<PanelProps> = (props: PanelProps) => {
  const [showMD, setShowMD] = useState(!props.summary);
  return (
    <div className="container mx-auto py-2 w-full">
      <div className="bg-transparent p-8 rounded-lg border border-gray-400 shadow-sm">
        <div className="flex flex-col mx-5 ">
          <div className="flex flex-row justify-self-center">
            <h5 className="text-sm font-semibold text-left text-rust">
              {props.title}
            </h5>
          </div>
          <div className="flex flex-row space-x-3 font-semibold mb-4 text-xs text-rust">
            <span>{props.metadata.date}</span>
            <span>{props.metadata.avgReadingTime}</span>
            <span>{props.metadata.category}</span>
          </div>
          {!showMD && <p>{props.summary}</p>}
          {showMD && <MarkdownViewer source={props.source!} />}
          {props.summary && (
            <button className="text-rust" onClick={() => setShowMD(!showMD)}>
              {showMD ? "Hide" : "Read More"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
