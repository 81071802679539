export interface SocialMediaInfo {
  screen_name?: string; // For Twitter
  id: string;
  valid: boolean;
}

export interface SocialMediaURLInterface {
  isValid(): boolean;
  getInfo(): SocialMediaInfo;
  parse(url: string): SocialMediaInfo;
  getViewer(id: string): JSX.Element;
}
export abstract class SocialMediaURLHelper implements SocialMediaURLInterface {
  abstract parse(url: string): SocialMediaInfo;
  abstract getViewer(): JSX.Element;
  abstract getInfo(): SocialMediaInfo;
  isValid(): boolean {
    return this.getInfo().valid;
  }
}
