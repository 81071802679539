import { Tweet } from "react-tweet";
import {
  SocialMediaURLHelper,
  SocialMediaInfo,
} from "@utils/social-media/social-media-info";

//Singleton class for TweetURL
export class TwitterURL extends SocialMediaURLHelper {
  private static instances: Map<string, TwitterURL> = new Map();
  private readonly info: SocialMediaInfo;

  private constructor(url: string) {
    super();
    this.info = this.parse(url);
  }

  public static getInstance(url: string): SocialMediaURLHelper {
    if (!this.instances.has(url)) {
      this.instances.set(url, new TwitterURL(url));
    }
    return this.instances.get(url)!;
  }

  parse(url: string): SocialMediaInfo {
    const tweetUrlRegex =
      /https?:\/\/(?:www\.)?(twitter|x)\.com\/([a-zA-Z0-9_]+)\/status\/(\d+)/;
    const match = url.match(tweetUrlRegex);

    if (match && match[2] && match[3]) {
      return {
        screen_name: match[2],
        id: match[3],
        valid: true,
      };
    }
    return { valid: false, id: "" };
  }

  getInfo(): SocialMediaInfo {
    return this.info;
  }
  getViewer(): JSX.Element {
    return <Tweet id={this.getInfo().id} />;
  }
}
