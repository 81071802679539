import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedin,
  faGithub,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

export const Contact: React.FC = () => {
  return (
    <section id="contact" className="text-center">
      <ul className="flex justify-center space-x-6">
        <li>
          <a
            href="https://twitter.com/vlkngulen"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-300 hover:text-blue-500 text-lg transition-colors duration-300"
          >
            <FontAwesomeIcon icon={faTwitter} />
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/in/volkanguelen"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-400 hover:text-blue-700 text-lg transition-colors duration-300"
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
        </li>
        <li>
          <a
            href="https://github.com/volkangulen"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-500 hover:text-gray-900 text-lg transition-colors duration-300"
          >
            <FontAwesomeIcon icon={faGithub} />
          </a>
        </li>
        <li>
          <a
            href="mailto:volkan@volkangulen.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-500 hover:text-gray-900 text-lg transition-colors duration-300"
          >
            <FontAwesomeIcon icon={faEnvelope} />
          </a>
        </li>
      </ul>
    </section>
  );
};
