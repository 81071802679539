import { Contact } from "@components/contact";

export const About: React.FC = () => {
  return (
    <div className="flex flex-row mb-10">
      <div className="flex-1">
        <h1 className="text-3xl font-bold">Volkan</h1>
        <h2 className="text-3xl font-bold">Gülen</h2>
        <div className="mt-4">
          <Contact />
        </div>
      </div>
      <div className="flex-2 pl-4">
        <p className="text-sm">
          With over {new Date().getFullYear() - 2016} years of industry
          experience, I have honed my craft in the art of designing and
          implementing cutting-edge backend architectures and solutions. My
          expertise is a fusion of Web Development, Database Management, and
          Software Infrastructure, where I excel in crafting scalable, secure,
          and lightning-fast backend systems. From the elegance of serverless
          architectures to the intricacies of microservices, service-oriented,
          and monolithic frameworks, I masterfully orchestrate both on-premise
          and cloud environments. My proficiency spans Unix and Windows
          platforms, and I command TypeScript, Python, C#, and Java with
          finesse. Fluent in English, I am passionate about driving innovation
          and leading cross-cultural, global teams to deliver exceptional,
          industry-defining software solutions.{" "}
        </p>
      </div>
    </div>
  );
};

export default About;
