import { SocialMediaURLHelper } from "@utils/social-media/social-media-info";
import { TwitterURL } from "@utils/social-media/twitter";
import { YouTubeURL } from "@utils/social-media/youtube";

export class SocialMediaFactory {
  static create(url: string): SocialMediaURLHelper | undefined {
    for (const SocialMediaClass of [TwitterURL, YouTubeURL]) {
      const instance = SocialMediaClass.getInstance(url);
      if (instance.isValid()) {
        return instance;
      }
    }
  }
}
