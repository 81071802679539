import { Panel } from "@components/panel";
import { About } from "@components/about";

function App() {
  return (
    <div>
      <div className="max-h-screen min-h-screen mx-36 flex flex-col justify-between">
        <main className="mt-10">
          <About />
          <Panel
            title="About Me"
            source="me"
            summary=""
            metadata={{
              date: "",
              category: "",
              avgReadingTime: "",
            }}
          />
        </main>
      </div>
    </div>
  );
}

export default App;
