import {
  SocialMediaURLHelper,
  SocialMediaInfo,
} from "@utils/social-media/social-media-info";
import YouTube from "react-youtube";

export class YouTubeURL implements SocialMediaURLHelper {
  private static instances: Map<string, YouTubeURL> = new Map();
  private readonly info: SocialMediaInfo;
  readonly viewer: JSX.Element | undefined;

  private constructor(url: string) {
    this.info = this.parse(url);
  }
  isValid(): boolean {
    return this.info.valid;
  }

  public static getInstance(url: string): SocialMediaURLHelper {
    if (!this.instances.has(url)) {
      this.instances.set(url, new YouTubeURL(url));
    }
    return this.instances.get(url)!;
  }

  parse(url: string): SocialMediaInfo {
    const youtubeUrlRegex =
      /(?:youtube\.com\/(?:[^/]+\/[^/]+\/|(?:v|embed)\/|.*[?&]v=)|youtu\.be\/)([^/?&]+)/;
    const match = url.match(youtubeUrlRegex);

    if (match && match[1]) {
      return {
        id: match[1],
        valid: true,
      };
    }
    return { valid: false, id: "" };
  }
  getInfo(): SocialMediaInfo {
    return this.info;
  }
  getViewer(): JSX.Element {
    return <YouTube videoId={this.getInfo().id} />;
  }
}
